import React, { FC } from 'react';

import { InjectedDataHookProps } from '../../../types';
import Button, { ButtonClickHandler } from '../Button';
import styles from './PopoverContent.scss';

export type RoleActionLinkClickHandler = ButtonClickHandler;

interface RoleActionLinkProps extends InjectedDataHookProps {
  isMenuItem?: boolean;
  onClick: RoleActionLinkClickHandler;
}

const RoleActionLink: FC<RoleActionLinkProps> = ({
  dataHook,
  isMenuItem = false,
  children,
  onClick,
}) => {
  return (
    <Button
      className={styles.actionContainer}
      dataHook={dataHook}
      isMenuItem={isMenuItem}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default RoleActionLink;

import React, { FC } from 'react';
import classNames from 'classnames';
import { Member } from '@wix/members-domain-ts';
import { Tooltip } from 'wix-ui-tpa';

import { InjectedClassNameProps } from '../../../types';
import styles from './MemberName.scss';
import {
  DEFAULT_HIDE_DELAY,
  DEFAULT_SHOW_DELAY,
} from '../../../constants/tooltip';

export interface MemberNameProps extends InjectedClassNameProps {
  member?: Member;
  name: string;
  tooltipClass?: string;
  goToMemberPage?: Function;
}

const MemberName: FC<MemberNameProps> = ({ name, className, tooltipClass }) => {
  return (
    <Tooltip
      content={name}
      className={tooltipClass}
      showDelay={DEFAULT_SHOW_DELAY}
      hideDelay={DEFAULT_HIDE_DELAY}
      appendTo="window"
    >
      <span className={classNames(className, styles.nameButton)}>{name}</span>
    </Tooltip>
  );
};

export default MemberName;

const screenContext = (
  rectStart: number,
  rectHeight: number,
  aWindow = window,
  aDocument = document,
) => {
  const scrollYOffset =
    aWindow.scrollY ||
    aWindow.pageYOffset ||
    (aDocument.documentElement && aDocument.documentElement.scrollTop) ||
    0;

  const bodyHeight = aDocument.body.offsetHeight;
  const remainingHeight = bodyHeight - scrollYOffset - rectStart;
  const fit = remainingHeight > rectHeight;
  const overTop = rectHeight - bodyHeight;
  const top =
    !fit && overTop < 0
      ? overTop - 10 // small margin for nicer look
      : 0;

  return {
    fit,
    top,
  };
};

export default screenContext;

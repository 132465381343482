import React, { FC, useState } from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import { IconTooltip } from 'wix-ui-tpa';
import WUTMoreIcon from 'wix-ui-icons-common/on-stage/More';

import { DataHook, Experiments, Position } from '../../../types';
import Popover, { MigratedPopover } from '../Popover';
import styles from './MoreButton.scss';
import Tooltip from '../Tooltip';
import More from './MoreIcon';
import {
  DEFAULT_HIDE_DELAY,
  DEFAULT_SHOW_DELAY,
} from '../../../constants/tooltip';

export interface MoreButtonProps {
  className?: string;
  iconContainerClass?: string;
  position?: Position;
  alignment: Position;
  isMobile: boolean;
  isRtlLocale: boolean;
  isDisabled?: boolean;
  dynamicPositioning?: boolean;
}

interface MoreIconProps {
  iconContainerClass?: string;
}

interface MoreIconWithTooltipProps extends MoreIconProps {
  tooltipPlacement: Position;
}

const MoreIcon: FC<MoreIconProps> = ({ iconContainerClass }) => {
  const { t } = useTranslation();

  return (
    <div
      aria-label={t('more-button.more-actions')}
      className={classNames(styles.icon, iconContainerClass)}
    >
      <More className={styles.moreIcon} />
    </div>
  );
};

const MoreIconWithTooltip: FC<MoreIconWithTooltipProps> = ({
  tooltipPlacement,
  iconContainerClass,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip text={t('more-button.tooltip')} placement={tooltipPlacement}>
      <MoreIcon iconContainerClass={iconContainerClass} />
    </Tooltip>
  );
};

const MoreButton: FC<MoreButtonProps> = ({
  className,
  position,
  alignment,
  isMobile,
  isRtlLocale,
  isDisabled,
  dynamicPositioning,
  children,
  iconContainerClass,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const { experiments } = useExperiments();
  const { t } = useTranslation();

  const isPopoverAndTooltipMigrationEnabled = experiments.enabled(
    Experiments.PopoverAndTooltipMigration,
  );

  if (isPopoverAndTooltipMigrationEnabled) {
    return (
      <IconTooltip
        data-hook={DataHook.MoreActionsTooltip}
        icon={
          <MigratedPopover
            dataHook={DataHook.MoreActionsCTA}
            alignment={alignment}
            dynamicPositioning={dynamicPositioning}
            content={children}
            disabled={isDisabled}
            onOpen={() => setPopoverOpen(true)}
            onClose={() => setPopoverOpen(false)}
            isOpen={isPopoverOpen}
          >
            <WUTMoreIcon className={styles.moreIcon} />
          </MigratedPopover>
        }
        message={t('more-button.tooltip')}
        showTooltip={
          isTooltipOpen && !isPopoverOpen && !isMobile && !isDisabled
        }
        tooltipProps={{
          placement: isRtlLocale ? Position.Right : Position.Left,
          showDelay: DEFAULT_SHOW_DELAY,
          hideDelay: DEFAULT_HIDE_DELAY,
        }}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
        disabled={isDisabled}
      />
    );
  }

  return (
    <Popover
      dataHook={DataHook.MoreActionsCTA}
      className={classNames(className, styles.container)}
      position={position}
      alignment={alignment}
      dynamicPositioning={dynamicPositioning}
      content={children}
      disabled={isDisabled}
      onOpen={() => setPopoverOpen(true)}
      onClose={() => setPopoverOpen(false)}
    >
      <div className={styles.more}>
        {!isMobile && !isDisabled && !isPopoverOpen ? (
          <MoreIconWithTooltip
            iconContainerClass={iconContainerClass}
            tooltipPlacement={isRtlLocale ? Position.Right : Position.Left}
          />
        ) : (
          <MoreIcon iconContainerClass={iconContainerClass} />
        )}
      </div>
    </Popover>
  );
};

export default MoreButton;

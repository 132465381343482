import React, { FC, SVGAttributes } from 'react';

const MoreIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4"
    height="15.406"
    viewBox="0 0 4 15.406"
    style={{ fillRule: 'evenodd' }}
    {...props}
  >
    <path
      d="M1000,381.719a1.928,1.928,0,1,1,2-1.927A1.964,1.964,0,0,1,1000,381.719Zm0-5.78a1.928,1.928,0,1,1,2-1.927A1.964,1.964,0,0,1,1000,375.939Zm0,7.706a1.928,1.928,0,1,1-2,1.927A1.968,1.968,0,0,1,1000,383.645Z"
      transform="translate(-998 -372.094)"
    />
  </svg>
);

export default MoreIcon;

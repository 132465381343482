import React, { FC } from 'react';
import { RoleAction } from '@wix/members-domain-ts';

import {
  AdditionalAction,
  DataHook,
  Position,
  Experiments,
} from '../../../types';
import MoreButton from '../MoreButton';
import PopoverContent, {
  MigratedPopoverContent,
  OnAdditionalAction,
  OnRoleAction,
} from './PopoverContent';
import { useExperiments } from '@wix/yoshi-flow-editor';

interface MemberMoreButtonProps {
  containerClass: string;
  iconContainerClass?: string;
  additionalActions: AdditionalAction[];
  rolesActions: RoleAction[];
  position?: Position;
  alignment: Position;
  isMobile: boolean;
  isRtlLocale: boolean;
  isDisabled?: boolean;
  dynamicPositioning?: boolean;
  onAdditionalAction: OnAdditionalAction;
  onRoleAction: OnRoleAction;
}

const MemberMoreButton: FC<MemberMoreButtonProps> = ({
  containerClass,
  iconContainerClass = '',
  additionalActions,
  rolesActions,
  position,
  alignment,
  isMobile,
  isRtlLocale,
  isDisabled,
  dynamicPositioning = false,
  onAdditionalAction,
  onRoleAction,
}) => {
  const { experiments } = useExperiments();

  if (!rolesActions.length && !additionalActions.length) {
    return null;
  }

  const isPopoverAndTooltipMigrationEnabled = experiments.enabled(
    Experiments.PopoverAndTooltipMigration,
  );

  return (
    <div data-hook={DataHook.MoreActionsWrapper} className={containerClass}>
      <MoreButton
        iconContainerClass={iconContainerClass}
        dynamicPositioning={dynamicPositioning}
        position={position}
        alignment={alignment}
        isRtlLocale={isRtlLocale}
        isMobile={isMobile}
        isDisabled={isDisabled}
      >
        {isPopoverAndTooltipMigrationEnabled ? (
          <MigratedPopoverContent
            additionalActions={additionalActions}
            rolesActions={rolesActions}
            isMobile={isMobile}
            onAdditionalAction={onAdditionalAction}
            onRoleAction={onRoleAction}
          />
        ) : (
          <PopoverContent
            additionalActions={additionalActions}
            rolesActions={rolesActions}
            isMobile={isMobile}
            onAdditionalAction={onAdditionalAction}
            onRoleAction={onRoleAction}
          />
        )}
      </MoreButton>
    </div>
  );
};

export default MemberMoreButton;

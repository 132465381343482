import React, { FC } from 'react';

import { InjectedTranslateProps } from '../../../types';
import roleIcons from '../../../constants/role-icons';
import Icon from '../Icon';
import styles from './PopoverContent.scss';

interface RoleActionIconProps extends InjectedTranslateProps {
  children: string;
}

const RoleActionIcon: FC<RoleActionIconProps> = ({ t, children }) => {
  const RoleIcon = roleIcons[children];

  if (!RoleIcon) {
    return null;
  }

  return (
    <Icon
      className={styles.actionIcon}
      iconClassName={styles.iconFill}
      icon={<RoleIcon />}
      t={t}
    />
  );
};

export default RoleActionIcon;

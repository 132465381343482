import React, { FC } from 'react';
import classNames from 'classnames';
import { IconTooltip } from 'wix-ui-tpa';

import {
  Position,
  InjectedTranslateProps,
  InjectedClassNameProps,
  Experiments,
} from '../../../types';
import styles from './Icon.scss';
import { useExperiments } from '@wix/yoshi-flow-editor';
import Tooltip from '../Tooltip';

const TOOLTIP_TIMEOUT = 250;

interface IconProps extends InjectedClassNameProps, InjectedTranslateProps {
  className?: string;
  iconClassName?: string;
  icon: any;
  tooltip?: string;
  placement?: Position;
}

const Icon: FC<IconProps> = ({
  className,
  iconClassName,
  icon,
  tooltip,
  placement,
  t,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const { experiments } = useExperiments();
  const isPopoverAndTooltipMigrationEnabled = experiments.enabled(
    Experiments.PopoverAndTooltipMigration,
  );

  const content = (
    <span className={classNames(iconClassName, styles.svgContainer)}>
      {icon}
    </span>
  );

  if (isPopoverAndTooltipMigrationEnabled) {
    return tooltip ? (
      <IconTooltip
        message={t(tooltip)}
        icon={icon}
        showTooltip={showTooltip}
        onClick={() => setShowTooltip(!showTooltip)}
        onClickOutside={() => setShowTooltip(!showTooltip)}
        contentClassName={classNames(iconClassName, styles.svgContainer)}
      />
    ) : (
      <div className={classNames(styles.container, className)}>{content}</div>
    );
  }

  return tooltip ? (
    <Tooltip text={t(tooltip)} placement={placement} timeout={TOOLTIP_TIMEOUT}>
      {content}
    </Tooltip>
  ) : (
    <div className={classNames(styles.container, className)}>{content}</div>
  );
};

export default Icon;
